import React, { useState } from "react";

export default function SearchMovies() {
	const [query, setQuery] = useState("");
	const [movies, setMovies] = useState([]);
	const searchMovies = async (e) => {
		e.preventDefault();
		const url = `https://api.themoviedb.org/3/search/movie?api_key=26a61c90c9aab2278445fcd2b7acad8b&language=en-US&query=${query}&page=1&include_adult=false`;
		try {
			const res = await fetch(url);
			const data = await res.json();
			console.log("~ data", data);
			setMovies(data.results);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div className='container'>
			<form className='form' onSubmit={searchMovies}>
				<label className='label' htmlFor='query'>
					Movie Name
				</label>
				<input className='input' type='text' name='query' placeholder='i.e. Avengers' value={query} onChange={(e) => setQuery(e.target.value)} />
				<button className='button btn-grad' type='submit'>
					Search
				</button>
			</form>
			<div className='card-list'>
				{movies
					.filter((movie) => movie.poster_path)
					.map((movie) => (
						<div className='card' key={movie.id}>
							<img className='card--image' src={`https://image.tmdb.org/t/p/w185_and_h278_bestv2/${movie.poster_path}`} alt={movie.title + " poster"} />
							<div className='card--content'>
								<h3 className='card--title'>{movie.title}</h3>
								<p>
									<small>
										<span>RELEASE DATE:</span> {movie.release_date}
									</small>
								</p>
								<p>
									<small>
										<span>RATING:</span> {movie.vote_average}
									</small>
								</p>
								<p className='card--desc'>{movie.overview}</p>
							</div>
						</div>
					))}
			</div>
		</div>
	);
}
