import React from "react";

const Header = () => {
	return (
		<div>
			<div className='container'>
				<h1 className='title'>
				
					React <span>Movie</span> Search
				</h1>
			</div>
		</div>
	);
};

export default Header;
